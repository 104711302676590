import {Controller} from '@hotwired/stimulus'
import SupportChecker from "../components/utility/support_checker";

export default class extends Controller {
    toggle(_event) {
        let user_password_input = this.element.parentElement.querySelector('input')

        this.element.querySelector('.fa-eye').parentElement.toggleAttribute('hidden')
        this.element.querySelector('.fa-eye-slash').parentElement.toggleAttribute('hidden')
        if (this.element.querySelector('.fa-eye').parentElement.hasAttribute('hidden')) {
            user_password_input.setAttribute('type', 'text')
        } else {
            user_password_input.setAttribute('type', 'password')
        }
    }
}
