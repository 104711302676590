import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="toggle-radio-group"
export default class extends Controller {
    static targets = ["checkBox", "radioGroupLabel", "radioGroup", "radioBtn"];

    connect() {
        this.toggle();
    }

    toggle() {
        const checkBox = document.getElementById('rated-question-checkbox');

        if (checkBox.checked) {
            this.enable();
        } else {
            this.disable();
        }
    }

    enable() {
        this.radioGroupLabelTargets.forEach(label => {
            label.classList.add('required');
        });

        this.radioGroupTargets.forEach(group => {
            group.classList.remove('disabled');
        });

        this.radioBtnTargets.forEach(btn => {
            btn.removeAttribute('disabled');
            btn.setAttribute('required', 'required');
        });
    }

    disable() {
        this.radioGroupLabelTargets.forEach(label => {
            label.classList.remove('required');
        });

        this.radioGroupTargets.forEach(group => {
            group.classList.add('disabled');
        });

        this.radioBtnTargets.forEach(btn => {
            btn.setAttribute('disabled', 'disabled');
            btn.removeAttribute('required');
            btn.checked = false;
        });
    }
}
